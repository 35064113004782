/*------------------------------------
    Element: Tabs
------------------------------------*/

.el-tabs {
  ul {
    display: block;
    list-style: none;
    text-align: center;
    padding: 0;
    margin-bottom: 30px;

    li {
      color: $base-1;
      cursor: pointer;
      font-size: 12px;
      font-weight: 500;
      position: relative;
      display: inline-block;
      padding: 7px 15px;
      border-radius: 30px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.active {
        background-color: $primary;
      }
    }
  }
}

/*------------------------------------
    Element: Slider
------------------------------------*/

.slick-slider {
  .slick-dots {
    li {
      top: 25px;
      position: relative;
      margin: 0 !important;

      button {
        &:before {
          color: $base-1;
          width: 8px !important;
          height: 8px !important;
          opacity: 1 !important;
          overflow: hidden;
          border: 1px solid $base-1;
          border-radius: 50%;
        }
      }
      &.slick-active {
        button {
          &:before {
            color: $primary;
            background-color: $primary;
          }
        }
      }
    }
  }
}

.el-slider-plr--15 {
  padding-left: -15px;
  padding-right: -15px;

  .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 992px) {
  .el-slider-plr--15 {
    &,
    .slick-slide {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

/*------------------------------------
    Element: Progress
------------------------------------*/

.el-progress {
  position: relative;
  margin-bottom: 60px;

  span {
    color: $text-color;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 15px;
  }
  .el-icon {
    margin-bottom: 25px;
  }
  .el-progress-count {
    top: 12px;
    right: 0;
    position: absolute;
  }
  div {
    height: 12px;
    border-radius: 3px;
    background-color: #eee;

    .el-progress-bar {
      width: 0;
      background-size: 1rem 1rem;
      background-color: $primary;
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      animation: progress-bar-stripes 1s linear infinite;
    }
  }
}

/*------------------------------------
    Element: Divider
------------------------------------*/

.divider {
  width: 63px;
  height: 4px;
  display: inline-block;
  position: relative;
  margin: 0 9px 15px;
  background-color: $primary;

  &.divider-1 {
    width: 63px;
    height: 4px;
    position: relative;
    margin-bottom: 15px;
    background: $primary;
  }
  &.divider-1-1 {
    margin-right: 12px !important;
  }
  &.divider-1-2 {
    &:before {
      left: -9px;
      width: 5px;
      height: 5px;
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: $primary;
    }
    &:after {
      left: -17px;
      width: 5px;
      height: 5px;
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: $primary;
    }
    &.divider-single {
      left: 12px;
    }
  }
}
/*------------------------------------
    Element: Headline
------------------------------------*/

.el-headline {
  width: 100%;
  position: relative;
  margin-bottom: $space / 2;

  .el-headline-title {
    font-size: 36px;
  }
  .el-headline-label {
    position: relative;

    span {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
    }
    &:before {
      left: 0;
      right: 0;
      bottom: 10px;
      width: 36px;
      height: 36px;
      opacity: 0.75;
      content: "";
      position: absolute;
      border-radius: 50%;
      margin: auto;
      background-color: $primary;
    }
  }
  &.center {
    max-width: $sm;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .el-headline {
    p {
      font-size: 14px;
    }
    .el-headline-title {
      font-size: 21px;
    }
  }
}

/*------------------------------------
    Element: Heading
------------------------------------*/

.el-heading {
  max-width: 674px;

  p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 50px;
    line-height: 1.4;
    margin-bottom: 36px;
  }
  h2 {
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 30px;
  }
  h3 {
    color: var(--base-1);
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  .label {
    color: $primary;
    display: block;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .el-icon {
    margin-bottom: 9px;
  }
  .el-heading-label {
    color: $primary;
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  &.el-heading-center {
    max-width: $md;
    text-align: center;
    margin: 0 auto $space / 2;

    .el-icon {
      .el-icon-title {
        padding-top: 15px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      &:before {
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  &.no-title p {
    padding-top: 7px;
  }
}

@media (max-width: 992px) {
  .el-heading {
    p {
      margin-bottom: 15px;
    }
    h1 {
      font-size: 33px;
      line-height: 1.4;
    }
    h3 {
      font-size: 24px;
    }
  }
}

/*------------------------------------
    Element: Scroll
------------------------------------*/

.el-scroll {
  left: 0;
  right: 0;
  bottom: 66px;
  width: 30px;
  height: 45px;
  z-index: 18;
  position: absolute;
  margin: auto;
  border-radius: 25px;
  border: 1px solid #fff;

  &:before {
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    top: 9px;
    width: 2px;
    height: 9px;
    display: block;
    background: #fff;
    border-radius: 3px;
    content: "";
    animation-name: scroll;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
  }
}
.el-scroll-line {
  left: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 50px;
  display: block;
  content: "";
  width: 1px;
  height: 50px;
  margin: auto;
  position: absolute;
  background-color: #fff;
}
/*------------------------------------
    Element: Blockquote
------------------------------------*/

.el-blockquote {
  overflow: hidden;
  position: relative;
  font-size: 16px;
  font-style: inherit;
  margin-top: 30px;
  padding: 15px 30px 15px 81px;

  p {
    font-weight: 100;
    text-align: left;
    margin-bottom: 15px;
  }
  .el-blockquote-icon {
    top: 12px;
    left: 0;
    width: 60px;
    height: 60px;
    color: $base-1;
    display: block;
    font-size: 18px;
    line-height: 60px;
    text-align: center;
    padding-top: 4px;
    border-radius: 50%;
    position: absolute;
    background: $primary;
  }
  .el-blockquote-author {
    color: $primary;
    font-size: 14px;
  }
}
/*------------------------------------
    Element: Circle-Side
------------------------------------*/

.el-circle-side {
  top: 0;
  width: 100vh;
  height: 100vh;
  opacity: 0.3;
  padding-top: 50%;
  padding-left: 50%;
  position: absolute;
  border-radius: 50%;
  background-color: $base-0;

  &.left {
    left: 7;
  }
  &.right {
    right: 7%;
  }
}
@media (max-width: 768px) {
  .el-circle-side {
    display: none;
  }
}
/*------------------------------------
    Element: List
------------------------------------*/

.el-list-check {
  list-style: none;
  padding: 0;
  margin: 0 15px 20px;

  li {
    display: block;
    font-weight: 500;
    margin-bottom: 9px;

    .icon {
      top: 1px;
      color: $success;
      position: relative;
      margin-right: 9px;
    }
  }
}

/*------------------------------------
    Element: Go Back
------------------------------------*/

.el-go-back {
  display: block;
  position: relative;
  color: $base-1;

  .icon {
    top: 2px;
    left: -3px;
    position: relative;
    margin-right: 6px;
  }
}

/*------------------------------------
    Element: Icon
------------------------------------*/

.portfolio-icon {
  margin-bottom: 5px;
  height: 20px;
  width: 20px;
  color: black;
}

.portfolio-icon:hover {
  color: var(--primary);
}

.el-icon {
  display: block;
  position: relative;
  margin-bottom: 30px;

  .el-icon-icon {
    top: 10px;
    left: 10px;
    z-index: 3;
    font-size: 30px;
    line-height: 36px;
    position: relative;
  }
  .el-icon-label,
  .el-icon-title,
  .el-icon-subtitle {
    display: block;
    padding-top: 9px;
    padding-left: 15px;
  }
  .el-icon-label {
    top: -1px;
    font-size: 14px;
    font-weight: 700;
    position: relative;
    margin-bottom: 3px;
  }
  .el-icon-title {
    top: -2px;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    margin-bottom: 3px;
  }
  .el-icon-small {
    font-size: 80%;
  }
  .el-icon-subtitle {
    font-size: 12px;
  }
  .el-icon-circle {
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    display: block;
    text-align: center;
    position: absolute;

    .el-icon-inner {
      top: 9px;
      left: 9px;
      font-size: 30px;
      position: absolute;
    }
    &:before {
      top: 0;
      left: 0;
      width: 36px;
      height: 36px;
      content: "";
      position: absolute;
      border-radius: 50%;
      background-color: $primary;
    }
  }
  &.el-icon-dark {
    .el-icon-title,
    .el-icon-subtitle {
      color: $black;
    }
  }
  &.el-icon-light {
    .el-icon-title,
    .el-icon-subtitle {
      color: $white;
    }
  }
  &:before {
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    content: "";
    position: absolute;
    border-radius: 50%;
    background: $primary;
  }
}

.icon {
  padding: 5px;
  font-size: 40px;
}

.side-icon {
  font-size: 28px;
  padding: 5px;
}

.content {
  margin-top: 5px;
}

/*------------------------------------
    Element: Card Icon
------------------------------------*/

.el-card-icon {
  padding-left: 60px;
  margin-bottom: 30px;

  p {
    font-size: 12px;
    line-height: 1.8;
    margin-bottom: 15px;
  }
  h5 {
    color: $base-1;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .el-icon {
    top: 3px;
    left: 15px;
    position: absolute;
  }
}

/*------------------------------------
    Element: Particles
------------------------------------*/

.el-particles {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}
